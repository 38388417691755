import React from 'react'
import axios from "axios";

const {useState, useEffect } = React;

const ProxyDataManager = () => {
    const [proxyData, setProxyData] = useState([]);
    useEffect(() => {
            setProxyData([]);
            axios.get("http://127.0.0.1:8081/proxy/test?a=1")
                .then(response => {
                    // console.log(response.data)
                    // setProxyData(response.data)
                });

            // console.log(proxyData);
        }, []
    );
    return proxyData;
}

export default ProxyDataManager;
