import logo from './logo.svg';
import './App.css';
import 'antd/dist/antd.js'

import React, { useState, useEffect } from 'react';
import axios from 'axios';


import { Layout, Menu, Breadcrumb, Typography, Card, Row, Col, Select, Table} from 'antd';
import proxyDataManager from "./ProxyDataManager";

const { Title, Paragraph } = Typography;

// const { useState, useEffect } = React;

const Header = () => (
    <header className="header">
        <div className="logo">FreeProxy</div>
        <nav className="nav">
            <ul className="nav-list">
                <li className="nav-item"><a href="#" className="nav-link">Index</a></li>
                <li className="nav-item"><a href="#" className="nav-link">Free Proxies</a></li>
            </ul>
        </nav>
    </header>
);

const Footer = () => (
    <footer>
        <p>&copy; 2024 all rights reserved</p>
    </footer>
);


function GetProxyData1() {
    const [proxyData, setProxyData] = useState({});
    useEffect(() => {
        async function GetMyData() {
            const res = await axios.get("http://127.0.0.1:8081/proxy/test?a=1");
            // console.log(res);
            setProxyData(res.data.data)
        }
        console.log(proxyData)
        GetMyData();
        if (proxyData.size > 0) {
            data = []
            for (const key in proxyData) {
                console.log(key)
            }
        }
    }, []);
    return <div></div>;
}


const countries = ["China", "United States", "United Kingdom", "Germany", "France", "Japan", "Korea", "Canada", "Australia", "Brazil"];
const anonymityLevels = ["Anonymous", "Transparent"];
const protocols = ["HTTP", "HTTPS", "SOCKS5"];

let data = []
// const res = await axios.get("http://127.0.0.1:8081/proxy/test?a=1");
const res = await axios.get("/api/proxy/test?a=1");
for (const d in res.data.data) {
    const pData = res.data.data.at(d);
    // console.log(pData)
    let anonyStr = ''
    if (0 === pData.anonymous) {
       anonyStr = 'Transparent';
    } else if (2 === pData.anonymous) {
        anonyStr = 'Anonymous'
    }

    let protocolStr = ''
    if (1 === pData.type) {
        protocolStr = 'HTTP';
    } else if (2 === pData.type) {
        protocolStr = 'HTTPS';
    } else if (3 === pData.type) {
        protocolStr = 'SOCKS4';
    } else if (4 === pData.type) {
        protocolStr = 'SOCKS5';
    }
    data.push({
        key: pData.id,
        proxyAddress: `${pData.ip} ${pData.port}`,
        // port: pData.port,
        country: pData.country,
        anonymity: anonyStr,
        protocol: protocolStr,
        latency: `${pData.latency} ms`,
    });
}

// for (let i = 0; i < 1000; i++) {
//     const country = countries[Math.floor(Math.random() * countries.length)];
//     const port = Math.floor(Math.random() * 65535) + 1;
//     const anonymity = anonymityLevels[Math.floor(Math.random() * anonymityLevels.length)];
//     const protocol = protocols[Math.floor(Math.random() * protocols.length)];
//     const latency = Math.floor(Math.random() * 500) + 1;
//
//     data.push({
//         key: i,
//         proxyAddress: `IP:PORT ${i+1}`,
//         port,
//         country,
//         anonymity,
//         protocol,
//         latency: `${latency} ms`,
//     });
// }

const FilterTable = () => {
    const [filters, setFilters] = useState({
        country: "",
        anonymity: "",
        protocol: "",
        latency: "",
    });

    const handleChange = (type, value) => {
        setFilters({
            ...filters,
            [type]: value,
        });
    };

    const filteredData = data.filter(item => {
        return (!filters.country || item.country === filters.country) &&
            (!filters.anonymity || item.anonymity === filters.anonymity) &&
            (!filters.protocol || item.protocol === filters.protocol) &&
            (!filters.latency || checkLatency(item.latency, filters.latency));
    });

    const columns = [
        { title: "IP:Port", dataIndex: "proxyAddress", key: "proxyAddress" },
        // { title: "Port", dataIndex: "port", key: "port" },
        { title: "Country", dataIndex: "country", key: "country" },
        { title: "Anonymity", dataIndex: "anonymity", key: "anonymity" },
        { title: "Protocol", dataIndex: "protocol", key: "protocol" },
        { title: "Latency", dataIndex: "latency", key: "latency" },
    ];

    return (
        // <div style={{ margin: "0 auto", maxWidth: "800px" }}>
         <div className={"main"}>
            <div>
                <Select size={"large"} style={{width: '150px'}} defaultValue="" onChange={(value) => handleChange("country", value)}>
                    <Select.Option value="">Country</Select.Option>
                    {countries.map(country => (
                        <Select.Option key={country} value={country}>{country}</Select.Option>
                    ))}
                </Select>
                <Select size={"large"} style={{width: '150px'}} defaultValue="" onChange={(value) => handleChange("anonymity", value)}>
                    <Select.Option value="">Anonymity</Select.Option>
                    {anonymityLevels.map(anonymity => (
                        <Select.Option key={anonymity} value={anonymity}>{anonymity}</Select.Option>
                    ))}
                </Select>
                <Select size={"large"} style={{width: '150px'}} defaultValue="" onChange={(value) => handleChange("protocol", value)}>
                    <Select.Option value="">Protocol</Select.Option>
                    {protocols.map(protocol => (
                        <Select.Option key={protocol} value={protocol}>{protocol}</Select.Option>
                    ))}
                </Select>
                <Select size={"large"} style={{width: '150px'}} defaultValue="" onChange={(value) => handleChange("latency", value)}>
                    <Select.Option value="">Latency</Select.Option>
                    <Select.Option value="0-100">0-100 ms</Select.Option>
                    <Select.Option value="101-200">101-200 ms</Select.Option>
                    <Select.Option value="201-300">201-300 ms</Select.Option>
                    <Select.Option value="301-400">301-400 ms</Select.Option>
                    <Select.Option value="401-500">401-500 ms</Select.Option>
                </Select>
            </div>
            <Table columns={columns} dataSource={filteredData} pagination={{ pageSize: 20 }} size="small" />
        </div>
    );
};



const checkLatency = (latency, range) => {
    const min = parseInt(range.split("-")[0]);
    const max = parseInt(range.split("-")[1]);
    const actual = parseInt(latency.split(" ")[0]); // Extract the numeric value from the latency string
    return actual >= min && actual <= max;
};


const App = () => (
    <div>
        <Header />
        <main>
            <FilterTable />
        </main>
        <Footer />
    </div>
);


export default App;
